import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import FacebookPixel from 'react-facebook-pixel';
import { PROD } from 'app/constants/env';

export const init = () => {
  if (!PROD) return;
  ReactGA.initialize(import.meta.env.PUBLIC_REACT_GA);
  TagManager.initialize({ gtmId: import.meta.env.PUBLIC_TAG_MANAGER });
  ReactGA.pageview(window.location.pathname + window.location.search);
  FacebookPixel.init(import.meta.env.PUBLIC_FACEBOOK_PIXEL);
  FacebookPixel.pageView();
};
